<template>
  <div class="container-center-horizontal">
    <div class="windows screen">
      <div class="overlap-group3">
        <div class="overlap-group">
          <div class="overlap-group">
            <div class="background"></div>
            <div class="graphic-elements">
              <img class="rectangle-10" src="@/assets/rectangle-26@1x.png" />
              <div class="text-1 raleway-black-selago-40px">
                <i18n path="global.title" />
              </div>
            </div>
            <div class="your-image-here border-4px-perano custom-radius"></div>
            <div class="text">
              <div class="text-3 cormorantgaramond-normal-selago-72px">
                <i18n path="result.download" />
              </div>
              <div class="text-2 raleway-light-eerie-black-36px">
                <div v-html="$t('result.windows')" />
              </div>
            </div>
            <div class="text-4 raleway-normal-dove-gray-36px">
              <CertInfo />
            </div>
            <div class="text-5 raleway-normal-torea-bay-36px">
              <i18n path="result.cert" />
            </div>
          </div>
          <a :href="passUrl">
            <div class="custom-button download-button-1">
              <div class="wallet-title raleway-black-torea-bay-40px">Wallet karte</div>
              <img id="linkqrcodeimage" src width="300" class="wallet-qrcode" />
            </div>
          </a>
          <img
            src="@/assets/download_plastic3.png"
            alt
            class="download-button-2 custom-button"
            @click="d_plastic=true"
          />

          <!-- <div class="rectangle-31 custom-button">
            <a :href="passUrl" target="_blank">
              <img id="linkqrcodeimage" src width="528" class="custom-button" />
            </a>
          </div>-->
        </div>
      </div>
    </div>
    <Footer />
    <Plastic v-model="d_plastic" />
    <ErrorDialog
      v-model="d_error"
      @close="$router.push('/preview')"
      :errorText="errorText"
      :errorCode="errorCode"
    />
  </div>
</template>

<script>
import CertInfo from "@/components/CertInfo.vue";
import QrCodeWithLogo from "qrcode-with-logos";
import Plastic from "@/components/Plastic.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "ResultWin",
  components: { CertInfo, Plastic, Footer },
  data() {
    return {
      d_plastic: false,
      d_error: false,
      errorText: null,
      errorCode: null,
    };
  },
  computed: {
    passUrl() {
      return this.$api.link("/pkpass/" + this.$store.getters["passdata"].file);
    },
  },
  mounted() {
    if (!this.$store.getters["passdata"].file) {
      this.errorText = this.$t("error.api");
      this.errorCode = "File not found";
      this.d_error = true;
    } else {
      console.log(this.passUrl);
      try {
        const el = document.getElementById("linkqrcodeimage");
        console.log(el);
        const qr = new QrCodeWithLogo({
          content: this.passUrl,
          width: 528,
          download: false,
          image: el,
        });
        qr.toImage();
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>

<style scoped>
.wallet-title {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
}
.wallet-qrcode {
  top: 42px;
  left: 15px;
  position: relative;
}
.download-button-1 {
  width: 330px;
  height: 445px;
  left: 180px;
  position: absolute;
  top: 915px;
  cursor: pointer;
  border-radius: 10% 0 0 10%;
  background: linear-gradient(0deg, #8b9bcc 0%, #dbe2f7 100%);
}
.download-button-2 {
  width: 330px;
  height: 445px;
  left: 567px;
  position: absolute;
  top: 915px;
  cursor: pointer;
  border-radius: 0 10% 10% 0;
  background: linear-gradient(0deg, #8b9bcc 0%, #dbe2f7 100%);
}
/* screen - windows */

.windows {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  overflow: hidden;
  width: 1080px;
}

.windows .overlap-group3 {
  height: 1920px;
  position: relative;
  width: 1081px;
}

.windows .overlap-group {
  height: 1920px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1081px;
}

.windows .background {
  background-color: var(--link-water);
  height: 1920px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.windows .graphic-elements {
  align-items: flex-start;
  background-color: var(--torea-bay-2);
  display: flex;
  height: 1323px;
  left: 1px;
  min-width: 1080px;
  padding: 66px 18px;
  position: absolute;
  top: 0;
}

.windows .rectangle-10 {
  height: 144px;
  object-fit: cover;
  width: 144px;
}

.windows .text-1 {
  letter-spacing: 12px;
  line-height: 64px;
  margin-left: 14px;
  margin-top: 93.67px;
  min-height: 49px;
  min-width: 718px;
  text-align: center;
  white-space: nowrap;
}

.windows .your-image-here {
  background-color: var(--white);
  height: 984px;
  left: 113px;
  position: absolute;
  top: 446px;
  width: 856px;
}

.windows .text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 125px;
  min-height: 1504px;
  padding: 8px 0;
  position: absolute;
  top: 303px;
  width: 834px;
}

.windows .text-3 {
  letter-spacing: 0.36px;
  line-height: 64px;
  margin-left: 2.16px;
  margin-top: 4px;
  min-height: 90px;
  min-width: 832px;
  text-align: center;
  white-space: nowrap;
}

.windows .text-2 {
  height: 302px;
  letter-spacing: 2.7px;
  line-height: 56px;
  margin-top: 1092px;
  text-align: center;
  width: 834px;
}

.windows .text-4 {
  height: 261px;
  left: 187px;
  letter-spacing: 2.7px;
  line-height: 70px;
  position: absolute;
  top: 565px;
  width: 710px;
}

.windows .text-5 {
  -webkit-text-stroke: 2px var(--camouflage);
  left: 204px;
  letter-spacing: 2.7px;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: 483px;
  white-space: nowrap;
  width: 678px;
}

.windows .path-18 {
  height: 112px;
  left: 373px;
  object-fit: cover;
  position: absolute;
  top: 953px;
  width: 112px;
}

.windows .path-19 {
  height: 112px;
  left: 599px;
  object-fit: cover;
  position: absolute;
  top: 953px;
  width: 112px;
}

.windows .path-20 {
  height: 112px;
  left: 373px;
  object-fit: cover;
  position: absolute;
  top: 1180px;
  width: 112px;
}

.windows .rectangle-31 {
  background-color: #a8a8a8;
  border: 1px solid var(--dove-gray);
  height: 528px;
  left: 277px;
  position: absolute;
  top: 860px;
  width: 528px;
}

.windows .component-1-10 {
  align-items: flex-start;
  display: flex;
  height: 118px;
  left: 0;
  min-width: 1080px;
  position: absolute;
  top: 1802px;
}

.windows .overlap-group2 {
  height: 118px;
  position: relative;
  width: 1080px;
}

.windows .rectangle-30 {
  background-color: var(--link-water);
  height: 118px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.windows .text-6 {
  left: 184px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.windows .text-7 {
  left: 609px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.windows .copyright {
  left: 447px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 56px;
  white-space: nowrap;
}

.windows .line-1 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1080px;
}
</style>